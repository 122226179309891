import React, { useState, useEffect } from "react";
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import { AppliedRoute, AuthenticatedRoute } from "./components";

import {
  CreateAccount,
  CreatePrimaryAccount,
  NotFound,
  Login,
  ChangePassword,
  History,
  TwitterTimeline,
  Education,
} from "./pages";

import {
  AuthStore,
  onError,
  Admin,
  User,
  AuthenticationState as AuthenticatedState,
  useAppContext,
} from "./libs";
import { useUser } from "./libs/userContext";

const VERIFICATION_CODE_PATH = "/VerificationCode/:username";

export default function Routes() {
  const {user, setUser} = useUser()
  const isVerificationPath = useRouteMatch(VERIFICATION_CODE_PATH);
  const { setUserAuthenticationStatus } = useAppContext();
  const [loadFlag, setLoadFlag] = useState(false);
  const location = useLocation();

  async function loadData() {
    try {
      if (isVerificationPath) {
        await signOutUser();
      } else {
        await getAndSetCurrentUser();
      }
    } catch (error) {
      if (error !== "No current user") {
        onError(error);
      }
    }
    setLoadFlag(true);
  }
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  async function getAndSetCurrentUser() {
    const currentAuthenticatedUser = await AuthStore.currentAuthenticatedUser();
    setUser(currentAuthenticatedUser);
  }

  function checkPermission(route, ...groups) {
    return (
      user !== null && user.belongToGroups(...groups) && route
    );
  }
  async function signOutUser() {
    await AuthStore.signOut();
    setUserAuthenticationStatus(AuthenticatedState.NotAuthenticated);
  }

  return (
    loadFlag && (
      <Switch>
        {checkPermission(
          <AuthenticatedRoute path="/" exact component={History} />,
          Admin,
          User
        )}
        {checkPermission(
          <AuthenticatedRoute
            path="/Notifications"
            exact
            component={TwitterTimeline}
          ></AuthenticatedRoute>,
          Admin,
          User
        )}
        {checkPermission(
          <AuthenticatedRoute
            path="/Education"
            exact
            component={Education}
          ></AuthenticatedRoute>,
          Admin,
          User
        )}
        <AppliedRoute path="/login" exact component={Login} />
        {user == null && (
          <AppliedRoute path="/" exact component={Login} />
        )}
        <AppliedRoute path="/ChangePassword" exact component={ChangePassword} />
        <AppliedRoute
          path="/CreatePrimaryAccount"
          exact
          component={CreatePrimaryAccount}
        />
        <AppliedRoute
          path={VERIFICATION_CODE_PATH}
          exact
          component={ChangePassword}
        />
        {checkPermission(
          <AuthenticatedRoute
            path="/CreateAccount"
            exact
            component={CreateAccount}
          />,
          Admin
        )}
        {/* Finally, catch all unmatched routes */}
        <Route component={NotFound} />
      </Switch>
    )
  );
}
