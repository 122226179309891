import React from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";

export default function Publish({ screenName }) {
  return (
    <TwitterTimelineEmbed
      sourceType="profile"
      screenName={screenName}
    ></TwitterTimelineEmbed>
  );
}
