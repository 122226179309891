import AuthStore from "../../libs/authStoreLib";
import React, { useState, useEffect } from "react";
import { Container, Navbar } from "react-bootstrap";
import Publish from "./Publish";

import "./TwitterTimeline.scss";

export default function TwitterTimeline() {
  const [twitterHandle, setTwitterHandle] = useState();
  const loadTimeline = async () => {
    var user = await AuthStore.currentAuthenticatedUser();
    setTwitterHandle(user.attributes.twitterHandle);
  };

  useEffect(() => {
    loadTimeline();
    // eslint-disable-next-line
  }, []);

  const renderTimeline = () => {
    return (
      twitterHandle && (
        <div className="justify-content-md-center scrollable">
          <Publish screenName={twitterHandle}></Publish>
        </div>
      )
    );
  };

  return (
    <div className="TwitterTimeline">
      <Container fluid className={"p-0"}>
        <Navbar id="navbar" bg="light" variant="light">
          <Navbar.Brand href="#">Twitter Feed</Navbar.Brand>
        </Navbar>
        {renderTimeline()}
      </Container>
    </div>
  );
}
