import { Svgs as svgLib, Admin, User } from "../../libs";
import { isPlainObject } from "lodash";

const getMenuItems = (user) => {
  let menuItems = {
    links: getLinks(user),
    support: getSupport(user),
  };

  return menuItems;
};

const getLinks = (user) => {
  let linkGroups = [getLinksForUser, getLinksForAdmin];

  let results = linkGroups.map((fn) => fn(user));
  return [].concat.apply([], results);
};

const getLinksForUser = (user) => {
  let links = [
    {
      id: 1,
      title: "History",
      link: "/",
      svg: svgLib.history,
    },
    {
      id: 2,
      title: "Education",
      link: "/Education",
      svg: svgLib.education,
    },
    {
      id: 3,
      title: "Twitter Feed",
      link: "/Notifications",
      svg: svgLib.twitter,
    },
  ];

  return user.belongToGroups(Admin, User) ? links : [];
};

const getLinksForAdmin = (user) => {
  let links = [
    {
      id: 4,
      title: "Create Account",
      link: "/CreateAccount",
      svg: svgLib.user,
    },
  ];
  return user.belongToGroups(Admin) ? links : [];
};

const getSupport = (user) => {
  let emailAttribute = "email";
  let defaultEmailText = "No Support Email";
  let defaultValue = "";

  let phoneAttribute = "phone";
  let defaultPhoneText = "No Support Number";

  let support = [
    {
      id: 5,
      title: getSupportValue(
        user.attributes.support,
        emailAttribute,
        defaultEmailText
      ),
      link: `mailto: ${getSupportValue(
        user.attributes.support,
        emailAttribute,
        defaultValue
      )}`,
      svg: svgLib.envelope,
      className: emailAttribute,
    },
    {
      id: 6,
      title: getSupportValue(
        user.attributes.support,
        phoneAttribute,
        defaultPhoneText
      ),
      link: `tel: ${getSupportValue(
        user.attributes.support,
        phoneAttribute,
        defaultValue
      )}`,
      svg: svgLib.phone,
      className: "",
    },
  ];

  return support;
};

const getSupportValue = (support, attributeName, defaultValue) => {
  return isPlainObject(support) ? support[attributeName] : defaultValue;
};

export { getMenuItems };
